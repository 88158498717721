<template>
	<div v-if="loaded">
		<div v-if="notAllowed">
			<not-allowed />
		</div>
		<div v-if="notFound">
			<not-found />
		</div>
		<div v-else>
			<div class="tree-page decision-tree">
				<div class="header-tree">
					<div class="tree-actions loop-selectable" v-if="Object.keys(modeSelectLoop).length">
						<div class="left-part">
							<button class="btn-tbf grey center" @click="modeSelectLoop = {}">
								<div class="text">{{ $t('general.cancel') }}</div>
							</button>
						</div>
						<div class="center-part">
							<div class="text">{{ $t('processes.mode_loop') }}</div>
						</div>
						<div class="right-part">
							<div class="back-button">
								<div class="btn-default-tbf close-btn" @click="modeSelectLoop = {}"><icon-plus class="icon-close" /></div>
							</div>
						</div>
					</div>
					<div class="tree-actions" v-else>
						<div class="left-part">
							<button class="btn-tbf blue center only-icon download-tree" id="btnExport" @click="exportTree">
								<div class="loader-spin white" v-if="loadingDownloadedImage"></div>
								<icon-download class="icon" v-else/>
							</button>

							<div class="filter-version dropdown" v-if="$resize &&  $mq.above(801)">
								<button class="btn-open-filter btn-tbf white" id="dropdownVersions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<div class="icon-circle" v-bind:class="{publish: selectedVersion.publish && processData.status == 'live'}"></div>
									<span class="text">{{ selectedVersion.name }}</span>
									<div v-if="processData.rights.entity_crud" class="btn-edit" @click.stop="showModal('version', {process_id: processData.id, version: selectedVersion, countVersions: processVersions.length})">
										<icon-edit />
									</div>
									<div class="icon-filter" v-if="processData.rights.entity_crud">
										<icon-arrow />
									</div>
								</button>

								<div class="dropdown-menu" aria-labelledby="dropdownVersions" v-if="processData.rights.entity_crud">
									<template v-for="version in processVersions">
										<div class="dropdown-item" @click="changeVersion(version)" v-bind:class="{active: selectedVersion.id == version.id && processData.status == 'live'}" :key="`version-${version.id}`">
											<div class="icon-circle" v-bind:class="{publish: version.publish && processData.status == 'live'}"></div>
											<span class="text">{{ version.name }}</span>
										</div>
									</template>
									<div class="dropdown-item add-btn">
										<button class="btn-tbf-link blue" @click="showModal('version', {process_id: processData.id, version_id: processData.version_id})"><div class="text">{{ $t('process-chart.add-new-version') }}</div></button>
									</div>
								</div>
							</div>
								<div class="actions-header" v-if="$resize && $mq.above(801) && processData.rights.entity_crud">
									<button @click="makeLiveVersion(selectedVersion)" :class="{active: !selectedVersion.publish && processData.status == 'draft'}"><div class="text">{{ selectedVersion.publish == true && processData.status == 'live' ? $t('procedures.make_draft') : $t('procedures.make_live') }}</div></button>
								</div>

							<div class="dropdown settings-org" v-if="$resize &&  $mq.below(800)">
								<button class="btn-tbf white only-icon tree-action-btn" @click="extendFilters = !extendFilters">
									<div class="icon"><icon-version /></div>
								</button>
							</div>
						</div>

						<div class="center-part">
							<div class="text">{{ processData.name }}</div>
							<div class="text-version" :class="{green: selectedVersion.publish == true && processData.status == 'live'}">{{ selectedVersion.publish == true && processData.status == 'live' ? $t('processes.version_live') : $t('processes.version_draft')}}</div>
						</div>

						<div class="right-part">
							<div class="btns-zoom">
								<div class="zoom-minus" @click="parseFloat(zoomPage).toFixed(1) > 0.50 ? zoomPage += -0.1 : zoomPage" v-bind:class="{disabled: zoomPage == 0.50}">-</div>
								<div class="current-zoom" @click="zoomPage = 1">{{ parseFloat(zoomPage * 100).toFixed(0) }}%</div>
								<div class="zoom-plus" @click="parseFloat(zoomPage).toFixed(1) == 1.50 ? zoomPage : zoomPage += 0.1" v-bind:class="{disabled: zoomPage == 1.50}">+</div>
							</div>
							<!-- <div class="filter-improvements dropdown" v-if="$resize && $mq.above(780)">
								<button class="btn-tbf white only-icon btn-question" id="dropdownImprovements" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<div class="status_has_active" v-if="improvementsData.active > 0"></div>
									<icon-question />
								</button>
								<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownVersions">
									<div class="dropdown-item" @click="showModal('improvements',  {id: processData.id, type: 'process'})">
										 <div class="text">{{ $t('processes.add_request') }}</div>
									</div>
									<div class="dropdown-item" @click="improvementsData.total > 0 ? $router.push({name: 'process-improvements', params: {slug: processData.slug}}) : ''">
										<div class="text">{{ improvementsData.total > 0 ? $t('processes.view_requests') : $t('processes.empty_requests') }}</div>
									</div>
								</div>
							</div> -->
							<button v-if="$resize && $mq.above(780)" class="btn-tbf white btn-improvement" @click="showModal('sidebar_improvements', {id: processData.id, type: 'process'})">
								<div class="text">{{ $t('procedures.improvement_requests') }}</div>
							</button>
							<div class="back-button">
								<div class="btn-default-tbf close-btn" @click="backToList"><icon-plus class="icon-close" /></div>
							</div>
						</div>
					</div>

					<div class="filters-extend" :class="[{mobile: $resize && $mq.below(1024)}]" v-if="extendFilters">
						<div class="filter-version dropdown">
							<button class="btn-open-filter btn-tbf white" id="dropdownVersions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<div class="icon-circle" v-bind:class="{publish: selectedVersion.publish}"></div>
								<span class="text">{{ selectedVersion.name }}</span>
								<div class="icon-filter">
									<icon-arrow />
								</div>
							</button>

							<div class="dropdown-menu" aria-labelledby="dropdownVersions" v-if="processData.rights.entity_crud">
								<template v-for="version in processVersions">
									<div class="dropdown-item" @click="changeVersion(version)" v-bind:class="{active: selectedVersion.id == version.id}" :key="`version-${version.id}`">
										<div class="icon-circle" v-bind:class="{publish: version.publish}"></div>
										<span class="text">{{ version.name }}</span>
									</div>
								</template>
								<div class="dropdown-item add-btn">
									<button class="btn-tbf-link blue" @click="showModal('version', {process_id: processData.id})"><div class="text">{{ $t('process-chart.add-new-version') }}</div></button>
								</div>
							</div>
						</div>

						<div class="btn-edit" v-if="processData.rights.entity_crud">
							<button class="btn-tbf white only-icon" @click="showModal('version', {process_id: processData.id, version: selectedVersion})">
								<icon-edit />
							</button>
						</div>

						<div class="btn-make-live" v-if="processData.rights.entity_crud" v-bind:class="{active: selectedVersion.publish == true}">
							<button class="btn-tbf white only-icon" @click="selectedVersion.publish == false ? makeLiveVersion(selectedVersion) : ''">
								<div class="icon-circle"></div>
							</button>
						</div>
					</div>
				</div>

				<div class="scrollable-chart" :class="{'loop-selectable': Object.keys(modeSelectLoop).length}" v-dragscroll>
					<div class="center-box" id="divToImage">
						<ProcessChart 
						class="organigram-tbf" 
						:class="{landscape: landscape.length, 'loop-selectable': Object.keys(modeSelectLoop).length}" 
						:fullJson="Object.keys(processJson).length ? processJson : {}" 
						:json="Object.keys(processJson).length ? processJson : {}" 
						:zoomPage="zoomPage" 
						:key="treeChartKey" 
						:processes="processesInfo" 
						:procedures="proceduresInfo" 
						:processData="processData" 
						:processTriggers="processTriggers" 
						:modeLoop="modeSelectLoop" 
						:temporaryNr="temporaryNr"
						:style="'transform: scale(' + zoomPage + ');'"/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<show-process-loader v-else/>
</template>


<script type="text/javascript">
	import NotAllowed from '../../General/NotAllowed'
	import NotFound from '../../General/NotFound'
	import ProcessChart from './ProcessChart'
	import IconPlus from '../../Icons/Plus'
	import IconArrow from '../../Icons/Arrow'
	import IconClose from '../../Icons/Close'
    import IconEdit from '../../Icons/Edit'
    import IconDownload from '../../Icons/Download'
    import IconQuestion from '../../Icons/Question'
    import IconVersion from '../../Icons/Version'
	import ShowProcessLoader from '../../PagesLoaders/ProcessShow'
	import * as htmlToImage from 'html-to-image'
	import download from 'downloadjs'

	export default {
		data() {
			return {
				loaded: false,
				notAllowed: false,
				notFound: false,
				extendFilters: false,
      			landscape: [],
      			treeChartKey: 1,
      			zoomPage: 1,
      			extendItems: true,
      			processVersions: [],
      			selectedVersion: {},
      			processesInfo: [],
      			proceduresInfo: [],
      			processesIds: [],
				proceduresIds: [],
				processData: {},
				processJson: {},
				improvementsData: {},
				totalChildrensJson: 0,
				processTriggers: [],
				versionId: '',
				loadingDownloadedImage: false,
				modeSelectLoop: {},
				countUniqueNr: 1,
				temporaryNr: 0
			};
		},
		components: {
			NotAllowed,
			NotFound,
			ProcessChart,
			IconPlus,
			IconArrow,
			IconClose,
			IconEdit,
			IconQuestion,
			IconVersion,
			IconDownload,
			ShowProcessLoader
		},
		watch:{
			$route (to, from){
				var jsonExtend = []
				localStorage.setItem('numberCount', 0)
				localStorage.setItem('numberCountExtend', 0)
				localStorage.setItem('jsonExtend', jsonExtend)

				this.getDataProcess().then(() => {
					this.getProcessVersions()
				})
            }
        },
        beforeCreate() {
        	localStorage.setItem('numberCount', 0)
        	localStorage.setItem('numberCountExtend', 0)
        },
		async mounted() {
			var jsonExtend = []
			localStorage.setItem('jsonExtend', jsonExtend)

			this.$root.$on("refreshProcessTree", (data) => {
				this.processJson = JSON.parse(data.json)
				this.processesInfo = data.processes_info
				this.proceduresInfo = data.procedures_info
				this.modeSelectLoop = {}
            });

			this.$root.$on('proccesNewVersion', (versionId) => {
				if(versionId) this.$router.push({ name: this.$route.name, query: { version: versionId }})
				this.getProcessVersions()
			})

			this.$root.$on('refreshImprovementsProcesses', () => {
				localStorage.setItem('numberCount', 0)
				this.getDataProcess()	
			})

			this.$root.$on("enter_mode_select_loop", (data) => {
				this.modeSelectLoop = data
			})

			this.$root.$on('add_end_step', (data) => {
				this.countUniqueNr = parseInt(localStorage.getItem('numberCount'));
				
				this.temporaryNr++;
				
				if(data.type != 'blank'){
					this.countUniqueNr++;
				}
				
				var newChildren = {
					unique_id: data.type == 'blank' ? data.unique_id : this.countUniqueNr,
					type: 'end',
					name: "End"
				}

				if(data.type == 'blank'){
					newChildren.condition = data.condition
					newChildren.extend = data.extend
					data = newChildren
				}else{
					if(data.children){
						data.children.push(newChildren)
					}else{
						data.children = [newChildren]
					}
				}
				this.storeJson(data)
			})

			await this.getDataProcess()
			await this.getProcessVersions()
		},
		beforeDestroy () {
			this.$root.$off('refreshProcessTree')
		},
		methods: {
			async getDataProcess(){
				await axios.get(`/processes/${this.$route.params.slug}`, {
					params: { version: this.$route.query.version }
				})
				.then(({data}) => {
					this.processJson = JSON.parse(data.data.json)
					this.processesInfo = data.data.processes_info
					this.proceduresInfo = data.data.procedures_info
					this.processData = {id: data.data.process.id, slug: data.data.process.slug, name: data.data.process.name, version_id: data.data.id, status: data.data.process.status, rights: data.data.rights}
					this.improvementsData = data.data.improvement_proposals
					this.versionId = data.data.id
					this.processTriggers = data.data.triggers

					this.countTotalItems(this.processJson)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getProcessVersions(){
				await axios.get(`${this.$route.params.slug}/process-versions`)
				.then(({data}) => {
					this.processVersions = data.data
					this.selectedVersion = this.$route.query.version ? this.processVersions.find(el => el.id == this.$route.query.version) : this.processVersions.find(el => el.id == this.versionId)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(()=>{
						this.loaded = true
					}, 300)
				})
			},
			changeVersion(version){
				this.selectedVersion = version
				this.$router.push({ name: this.$route.name, query: { version: version.id }})
			},
			makeLiveVersion(version){
				axios.patch(`/process-versions/${version.id}`, {name: version.name, process_id: this.processData.id, is_publish: version.publish ? 0 : 1})
				.then(() => {
					this.getProcessVersions()
					this.getDataProcess()
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			countTotalItems(jsonData){
				this.totalChildrensJson += 1
				if(jsonData.hasOwnProperty('children') && jsonData.children.length){
					jsonData.children.forEach(el => {
						var number = el.unique_id
						this.countUniqueNr = this.countUniqueNr >= number ? this.countUniqueNr : number++
						this.countTotalItems(el)
					})
				}
				localStorage.setItem('numberCountExtend', this.totalChildrensJson)
			},
			backToList(){
				if(this.$route.query.parent){
					this.$router.push({ name: 'process-show', params: {slug: this.$route.query.parent} })	
				}else {
					this.$router.push({ name: 'processes'})
				}
			},
			updateTree(){
				this.treeChartKey += 1
			},
			extendTree(){
				localStorage.setItem('numberCountExtend', 0)

				setTimeout(() => {
					this.extendItems = !this.extendItems
					this.$root.$emit('toggleExtendAll', this.extendItems)
				}, 0)
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			exportTree(){
				this.loadingDownloadedImage = true

				setTimeout(() => {
					var btnSubmit = document.getElementById('btnExport');
					btnSubmit.disabled = true

					htmlToImage.toPng(document.getElementById('divToImage'))
					.then((dataUrl) => {
						download(dataUrl, `${ this.processData.name }.png`);
					})
					.then(() => {
						btnSubmit.disabled = false
						this.loadingDownloadedImage = false
					})
				}, 100)
			},
			getObjects(obj, val, newVal) {
				var newValue = newVal;
				var objects = [];
				for (var i in obj) {
					if (!obj.hasOwnProperty(i)) continue;

					if (typeof obj[i] == 'object') {
						objects = objects.concat(this.getObjects(obj[i], val, newValue));
					} else if (i == 'unique_id' && obj['unique_id'] == val) {
						var blank = obj['type'] == 'blank' ? true : false

						if(newValue.children){ obj['children'] = newValue.children }
						if(newValue.unique_id){ obj['unique_id'] = newValue.unique_id }
						if(newValue.id){ obj['id'] = newValue.id }
						if(newValue.type){ obj['type'] = newValue.type }
						if(newValue.extend){ obj['extend'] = newValue.extend }
						if(newValue.color){ obj['color'] = newValue.color }
						if(newValue.selectedParentLoop){ obj['selectedParentLoop'] = newValue.selectedParentLoop }

						if(newValue.name){ obj['name'] = newValue.name }

						if(blank){
							if(obj['type'] == 'process') this.processesIds.push(obj['id'])
							if(obj['type'] == 'procedure') this.proceduresIds.push(obj['id'])
						}

						if(newValue.children){
							newValue.children.map(el => {
								if(el.type == 'process') this.processesIds.push(el.id)
								if(el.type == 'procedure') this.proceduresIds.push(el.id)
							})
						}
					}

					if ( 
						i == 'unique_id' && 
						( 
							( newValue.hasOwnProperty('selectedParentLoop') && obj['unique_id'] == newValue.selectedParentLoop.unique_id ) || 
							( newValue.hasOwnProperty('temporarySelectedParentLoop') && obj['unique_id'] == newValue.temporarySelectedParentLoop.unique_id)
						)
					 ) {
						if(!obj.hasOwnProperty('is_parent')){
							if(newValue.hasOwnProperty('temporarySelectedParentLoop')) {
								obj['is_parent'] = [newValue.children[0].unique_id]
							} else {
								obj['is_parent'] = [val]
							}
						} else {

							if(newValue.hasOwnProperty('temporarySelectedParentLoop')) {
								obj['is_parent'].push(newValue.children[0].unique_id)
							} else {
								obj['is_parent'].push(val)
							}
						}
					}

					if (i == 'type') {
						if(obj['type'] == 'process') this.processesIds.push(obj['id'])
						else if (obj['type'] == 'procedure') this.proceduresIds.push(obj['id'])
					}

				}

				return obj;
			},
			storeJson(newChildData){
				this.processesIds = []
				this.proceduresIds = []

				var newJson = this.getObjects(this.processJson, newChildData.unique_id, newChildData)

				axios.post(`/procedures/${this.processData.slug}/sync`, { 
					version_id: this.processData.version_id, 
					procedures: this.proceduresIds, 
					processes: this.processesIds, 
					json: JSON.stringify(newJson)
				})
				.then(({data}) => {
					this.$root.$emit('refreshProcessTree', {json: data.data.json, processes_info: data.data.processes_info, procedures_info: data.data.procedures_info})
				})
				.finally(() => {
					setTimeout(()=>{
						this.$emit("close_modal")
					}, 100)
				})
			}
		}
	};
</script>